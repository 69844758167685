<template>
  <v-card-text>
    <div v-if="isLoading">
      <v-progress-linear indeterminate></v-progress-linear>
    </div>
    <div v-else>
      <v-snackbar
        v-model="snackbar"
        :multi-line="true"
        color="warning"
        timeout="-1"
        outlined
        absolute
        bottom
        right
        transition="slide-x-reverse-transition"
      >
        A Survey is using this language, language must be removed from survey
        before this language is deleted
        <template v-slot:action="{ attrs }">
          <v-btn icon color="warning" v-bind="attrs" @click="snackbar = false">
            <v-icon>mdi-close</v-icon>
            <span class="d-none" aria-hidden="true">Close</span>
          </v-btn>
        </template>
      </v-snackbar>
      <v-card class="mb-12">
        <v-card-text>
          <dl>
            <v-container fluid>
              <v-row>
                <v-col cols="12" sm="4">
                  <dt>Language Name:</dt>
                  <dd v-text="name"></dd>
                </v-col>
                <v-col cols="12" sm="4">
                  <dt>Description:</dt>
                  <dd v-text="description"></dd>
                </v-col>
                <v-col cols="12" sm="4">
                  <dt>ISO Code:</dt>
                  <dd v-text="iso_code"></dd>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="4">
                  <dt>Status:</dt>
                  <dd>
                    <span>
                      <v-icon v-if="is_active" color="green"
                        >mdi-check-circle</v-icon
                      >
                      <v-icon v-else color="red">mdi-cancel</v-icon>
                      {{ is_active ? "Active" : "Inactive" }}
                    </span>
                  </dd>
                </v-col>
              </v-row>
            </v-container>
          </dl>
        </v-card-text>
      </v-card>
    </div>
  </v-card-text>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  name: "LanguagesView",
  props: {
    id: {
      type: String,
      default: "",
    },
  },
  computed: {
    ...mapFields("systemLanguages", {
      name: "language.name",
      description: "language.description",
      iso_code: "language.iso_code",
      is_active: "language.is_active",
    }),
    ...mapState("systemLanguages", ["language", "error", "included"]),
    title() {
      return "View Language";
    },
    showLanguageEditButton() {
      return this.acceptRoles(["Admin", "Super User"]);
    },
    showLanguageDeleteButton() {
      return this.acceptRoles(["Admin", "Super User"]);
    },
    ...mapGetters(["acceptRoles"]),
  },
  data() {
    return {
      isLoading: false,
      snackbar: false,
    };
  },
  methods: {
    ...mapActions("navigationBar", [
      "clearActionButtons",
      "clearTitleObject",
      "setActionButtons",
      "setBreadcrumbs",
      "setTitleObject",
    ]),
    ...mapActions("systemLanguages", [
      "getLanguage",
      "clearLanguage",
      "deleteLanguage",
    ]),
    // delete language
    remove() {
      this.deleteLanguage(this.id).then(([success]) => {
        if (success) {
          this.$router.push({ name: "LanguagesIndex", params: {} });
        } else {
          this.snackbar = true;
        }
      });
    },
  },
  async beforeMount() {
    this.isLoading = true;
    await this.getLanguage(this.id).catch(() => {
      this.$router.push({ name: "NotFound" });
    });
    this.isLoading = false;
    this.setBreadcrumbs();
    this.setTitleObject(this.name);
    let actionButtons = [];
    if (this.showLanguageDeleteButton) {
      actionButtons.push({
        attributes: {
          onConfirm: () => this.remove(),
        },
        component: "DeleteButton",
        index: 0,
      });
    }
    if (this.showLanguageEditButton) {
      actionButtons.push({
        attributes: {
          to: "/languages/edit/" + this.id,
        },
        component: "EditButton",
        index: 1,
      });
    }
    this.setActionButtons(actionButtons);
  },
  destroyed() {
    this.clearLanguage();
    this.clearActionButtons();
    this.clearTitleObject();
  },
};
</script>

<style scoped>
dt {
  font-weight: bold;
  font-size: 1.1rem;
}
</style>
